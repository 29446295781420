import landinglogo from '../assets/tonykang.gif';

const LandingPage = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={landinglogo} alt="logo" />
      </header>
    </div>
  );
}

export default LandingPage;
